<template>
  <div :class="$style.question">
    <YCheckbox
      v-if="checkboxTypes.indexOf(question.type) !== -1"
      :question="question"
      :options="answerOptions"
      @changeCheck="handlerChange"
    />

    <YRadio
      v-if="radioTypes.indexOf(question.type) !== -1"
      :question="question"
      :options="answerOptions"
      @changeRadio="handlerChange"
    />

    <YField
      v-if="fieldTypes.indexOf(question.type) !== -1"
      :question="question"
      :value="answerValue"
      @change="handlerChange"
    />

    <YPicker
      v-if="pickerTypes.indexOf(question.type) !== -1"
      :question="question"
      :value="answerValue"
      @change="handlerChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import YCheckbox from "../../components/question/checkbox.vue";
import YRadio from "../../components/question/radio.vue";
import YPicker from "../../components/question/picker";
import YField from "../../components/question/field";

export default {
  components: { YCheckbox, YRadio, YPicker, YField },
  props: {
    index: Number,
    question: Object,
    value: {
      type: Object,
      default: () => {
        return {
          options: [],
          value: "",
        };
      },
    },
  },
  computed: {
    ...mapState({
      checkboxTypes: (state) => state.checkboxTypes,
      radioTypes: (state) => state.radioTypes,
      pickerTypes: (state) => state.pickerTypes,
      fieldTypes: (state) => state.fieldTypes,
    }),
  },
  watch: {
    value: {
      handler: function (val) {
        switch (this.question.type) {
          case "MULTIPLE_CHOICE":
          case "ONE_CHOICE":
            this.answerOptions = val.options;
            break;
          default:
            this.answerValue = val.value;
            break;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      answerOptions: [],
      answerValue: "",
    };
  },
  methods: {
    handlerChange(val) {

      this.answerOptions = val;

      const value = {
        index: this.index,
        question: this.question,
      };

      if (
        this.radioTypes.indexOf(this.question.type) !== -1 ||
        this.checkboxTypes.indexOf(this.question.type) !== -1
      ) {
        //单选和多选
        value.options = val;
      }

      if (this.radioTypes.includes(this.question.type)) {
        value.value = val[0].value;
      }

      if (
        this.pickerTypes.indexOf(this.question.type) !== -1 ||
        this.fieldTypes.indexOf(this.question.type) !== -1
      ) {
        //选择和输入
        value.value = val;
        this.answerValue = val;
      }

      this.$emit("change", value);
    },
  },
  mounted() {
    switch (this.question.type) {
      case "MULTIPLE_CHOICE":
      case "ONE_CHOICE":
        this.answerOptions = this.value.options;
        break;
      default:
        this.answerValue = this.value.value;
        break;
    }
  },
};
</script>

<style lang="less" module>
.question {
  // min-height: 50vh;

  span {
    font-size: 0.4rem;
  }
}
</style>