<template>
  <van-radio-group
    v-model="radioValue"
    :checked-color="primaryColor"
    @change="handlerChange"
  >
    <van-collapse v-if="question.multilaminate" v-model="active" accordion>
      <template v-for="(option, index) in question.options">
        <van-collapse-item
          :key="index"
          :title="option.content"
          :name="option.id"
        >
          <template v-for="subordinate in option.subordinates">
            <van-radio
              :key="subordinate.id"
              :class="$style.radio"
              :name="subordinate.id"
            >
              {{ subordinate.content }}
            </van-radio>
            <van-field
              :key="subordinate.id + 'field'"
              :class="$style.other"
              v-if="subordinate.customized"
              v-show="radioValue == subordinate.id"
              v-model="otherValue[subordinate.id]"
              size="large"
              placeholder="请输入..."
              @input="handlerChange"
            />
          </template>
        </van-collapse-item>
      </template>
    </van-collapse>
    <div v-else>
      <template v-for="(option, index) in question.options">
        <van-radio
          :key="index"
          :class="$style.radio"
          :name="option.id"
          :disabled="option.disabled"
        >
          {{ option.content }}
        </van-radio>
        <van-field
          :key="index + 'field'"
          :class="$style.other"
          v-if="option.customized"
          v-show="radioValue == option.id"
          v-model="otherValue[option.id]"
          size="large"
          placeholder="请输入..."
          @input="handlerChange"
        />
      </template>
    </div>
  </van-radio-group>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    question: Object,
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.application.properties.primaryColor,
    }),
  },
  watch: {
    options: {
      handler: function (val) {
        if (val.length > 0) {
          if (val[0].option.id !== this.radioValue) {
            this.radioValue = val[0].option.id;
          }

          const otherValues = {};

          otherValues[val[0].option.id] = val[0].value || "";

          if (!this.isObjShallowEqual(otherValues, this.otherValue)) {
            this.otherValue = otherValues;
          }
        } else {
          this.radioValue = "";
          //   const otherValues = {};
        }
      },
      deep: true,
      //   immediate: true,
    },
  },
  data() {
    return {
      radioValue: "",
      active: 0,
      otherValue: {},
    };
  },
  methods: {
    isObjShallowEqual(obj1, obj2) {
      const keys1 = Object.getOwnPropertyNames(obj1);
      const keys2 = Object.getOwnPropertyNames(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      const flag = keys1.every((key) => {
        const type = typeof obj1[key];
        // do not check function, array, object
        if (["function", "array", "object"].includes(type)) {
          return type === typeof obj2[key];
        }
        // if unequal, return true
        if (obj1[key] !== obj2[key]) {
          return false;
        }
        return true;
      });
      // if found unequal, then return false, which means unequal
      return flag;
    },
    async handlerChange(value) {
      const optionAnswer = {
        option: {
          id: value,
        },
      };

      if (this.otherValue[value]) {
        optionAnswer.value = this.otherValue[value];
      } else {
        optionAnswer.value = this.question.options.filter(
          (option) => option.id === value
        )[0].content;
      }

      this.$emit("changeRadio", [optionAnswer]);
    },
  },
};
</script>

<style lang="less" module>
.radio {
  margin-top: 8px;
  padding: 16px 10px;
  border: 1px solid #dddddd;
}
.other {
  border: 1px solid #dddddd;
}
</style>