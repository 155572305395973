<template>
  <van-checkbox-group
    v-model="checkboxValue"
    :max="question.maximum"
    :checked-color="primaryColor"
    @change="handlerChange"
  >
    <van-collapse v-if="question.multilaminate" v-model="active" accordion>
      <template v-for="(option, index) in question.options">
        <van-collapse-item
          :key="index"
          :title="option.content"
          :name="option.id"
        >
          <template v-for="subordinate in option.subordinates">
            <van-checkbox
              :key="subordinate.id"
              :class="$style.checkbox"
              :name="subordinate.id"
              shape="square"
            >
              {{ subordinate.content }}
            </van-checkbox>
            <van-field
              :key="subordinate.id + 'field'"
              :class="$style.other"
              v-if="subordinate.customized"
              v-show="checkboxValue.includes(subordinate.id)"
              v-model="otherValue[subordinate.id]"
              size="large"
              placeholder="请输入..."
              @input="handlerChange"
            />
          </template>
        </van-collapse-item>
      </template>
    </van-collapse>
    <div v-else>
      <template v-for="(option, index) in question.options">
        <van-checkbox
          :key="index"
          :class="$style.checkbox"
          :name="option.id"
          :disabled="option.disabled"
          shape="square"
        >
          {{ option.content }}
        </van-checkbox>
        <van-field
          :key="index + 'field'"
          :class="$style.other"
          v-if="option.customized"
          v-show="checkboxValue.includes(option.id)"
          v-model="otherValue[option.id]"
          size="large"
          placeholder="请输入..."
          @input="handlerChange"
        />
      </template>
    </div>
  </van-checkbox-group>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    question: Object,
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.application.properties.primaryColor,
    }),
  },
  watch: {
    options: {
      handler: function (val) {
        const checkboxValues = val.map((v) => v.option.id);

        if (!checkboxValues.equals(this.checkboxValue)) {
          this.checkboxValue = checkboxValues;
        }

        const otherValues = val.reduce((accumulator, currentValue) => {
          accumulator[currentValue.option.id] = currentValue.value || "";
          return accumulator;
        }, {});

        if (!this.isObjShallowEqual(otherValues, this.otherValue)) {
          this.otherValue = otherValues;
        }
      },
      deep: true,
      //   immediate: true,
    },
  },
  data() {
    return {
      checkboxValue: [],
      active: 0,
      otherValue: {},
    };
  },
  methods: {
    isObjShallowEqual(obj1, obj2) {
      const keys1 = Object.getOwnPropertyNames(obj1);
      const keys2 = Object.getOwnPropertyNames(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      const flag = keys1.every((key) => {
        const type = typeof obj1[key];
        // do not check function, array, object
        if (["function", "array", "object"].includes(type)) {
          return type === typeof obj2[key];
        }
        // if unequal, return true
        if (obj1[key] !== obj2[key]) {
          return false;
        }
        return true;
      });
      // if found unequal, then return false, which means unequal
      return flag;
    },
    async handlerChange() {
      const optionAnswers = this.checkboxValue.map((value) => {
        const optionAnswer = {
          option: {
            id: value,
          },
        };

        if (this.otherValue[value]) {
          optionAnswer.value = this.otherValue[value];
        }

        return optionAnswer;
      });

      this.$emit("changeCheck", optionAnswers);
    },
  },
};
</script>

<style lang="less" module>
.checkbox {
  margin-top: 8px;
  padding: 16px 10px;
  border: 1px solid #dddddd;
}
.other {
  border: 1px solid #dddddd;
}
</style>