<template>
  <div
    v-if="
      !(
        this.country !== 156 &&
        ['USER_PROVINCE', 'USER_CITY', 'USER_DISTRICT'].indexOf(
          question.type
        ) !== -1
      )
    "
  >
    <van-field
      v-model="value"
      readonly
      clickable
      :required="question.required"
      :label="question.content"
      :placeholder="question.description || '请点击选择'"
      @click="isVisible = true"
    />
    <van-popup v-model="isVisible" position="bottom" round>
      <van-picker
        :columns="columns"
        :title="question.content"
        :loading="isLoading"
        show-toolbar
        :default-index="defaultValue"
        @confirm="handlerChange"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import area from "../../data/area";
import countries from "../../data/country.json";

export default {
  props: {
    question: Object,
    value: String,
  },
  computed: {
    ...mapState({
      pickerTypes: (state) => state.pickerTypes,
      country: (state) => state.country,
      province: (state) => state.province,
      city: (state) => state.city,
      district: (state) => state.district,
    }),
  },
  watch: {
    country: {
      handler: function (value) {
        console.log(value);
      },
    },
    province: {
      handler: function (value) {
        this.isLoading = true;
        if (this.question.type === "USER_CITY") {
          //城市筛选
          let cities = Object.keys(area.city_list);

          if (value) {
            cities = cities.filter((code) => {
              return code >= value && code < Number(value) + 10000;
            });
          }

          this.columns = cities.map((code) => {
            return area.city_list[code];
          });

          this.$emit("change", "");
        }
        this.isLoading = false;
      },
    },
    city: {
      handler: function (value) {
        this.isLoading = true;

        if (this.question.type === "USER_DISTRICT") {
          //区筛选
          let counties = Object.keys(area.county_list);

          if (value) {
            counties = counties.filter((code) => {
              return code >= value && code < Number(value) + 100;
            });
          }

          this.columns = counties.map((code) => {
            return area.county_list[code];
          });

          this.$emit("change", "");
        }
        this.isLoading = false;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isVisible: false,
      defaultValue: 0,
      columns: [],
    };
  },
  methods: {
    ...mapMutations({
      setCountry: "setCountry",
      setProvince: "setProvince",
      setCity: "setCity",
      setDistrict: "setDistrict",
    }),
    ...mapActions({}),
    handlerChange(value) {
      switch (this.question.type) {
        case "USER_COUNTRY":
          this.setCountry(
            countries.find((country) => {
              return country.cnname === value;
            }).numeric || ""
          );
          break;
        case "USER_PROVINCE":
          this.setProvince(
            Object.keys(area.province_list).find((code) => {
              return area.province_list[code] === value;
            }) || ""
          );
          break;
        case "USER_CITY":
          this.setCity(
            Object.keys(area.city_list).find((code) => {
              return area.city_list[code] === value;
            }) || ""
          );
          break;
        case "USER_DISTRICT":
          break;
        default:
          break;
      }

      this.$emit("change", value);
      this.isVisible = false;
    },
  },
  mounted() {
    // let provinces;
    let cities;
    switch (this.question.type) {
      case "USER_COUNTRY":
        this.columns = countries.map((country) => {
          return country.cnname;
        });
        console.log(this.question.properties.values);
        if (
          this.question.properties &&
          this.question.properties.values &&
          this.question.properties.values.length > 0
        ) {
          this.columns = this.columns.filter((column) => {
            return this.question.properties.values.indexOf(column) > -1;
          });
        }

        this.defaultValue = this.columns.findIndex(
          (column) => column === "中国"
        );

        break;
      case "USER_PROVINCE":
        this.columns = Object.values(area.province_list);
        break;
      case "USER_CITY":
        cities = Object.keys(area.city_list);
        if (this.province) {
          cities = cities.filter((cityCode) => {
            return (
              this.province <= cityCode && this.province + 10000 > cityCode
            );
          });
        }
        this.columns = Object.values(
          cities.map((cityCode) => {
            return area.city_list[cityCode];
          })
        );
        this.columns = Object.values(area.city_list);
        break;
      case "USER_DISTRICT":
        this.columns = Object.values(area.county_list);
        break;
      default:
        break;
    }
  },
};
</script>

<style>
</style>