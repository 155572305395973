var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !(
      this.country !== 156 &&
      ['USER_PROVINCE', 'USER_CITY', 'USER_DISTRICT'].indexOf(
        _vm.question.type
      ) !== -1
    )
  )?_c('div',[_c('van-field',{attrs:{"readonly":"","clickable":"","required":_vm.question.required,"label":_vm.question.content,"placeholder":_vm.question.description || '请点击选择'},on:{"click":function($event){_vm.isVisible = true}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('van-popup',{attrs:{"position":"bottom","round":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('van-picker',{attrs:{"columns":_vm.columns,"title":_vm.question.content,"loading":_vm.isLoading,"show-toolbar":"","default-index":_vm.defaultValue},on:{"confirm":_vm.handlerChange}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }