
export default {

  async getQuestionnaire(questionnaire) {

    const result = await window.axios({
      url: `/questionnaire/${questionnaire.id}`,
      method: "GET",
    });

    return result
  }
}
