<template>
  <div>
    <van-field
      :value="value"
      :label="question.content"
      :name="question.key"
      :maxlength="question.maximum"
      :placeholder="question.description || '请输入'"
      :disabled="question.disabled"
      :required="question.required"
      @input="handlerChange"
    />
  </div>
</template>

<script>
import { Toast } from "vant";
import blacklist from "../../data/blacklist.json";

export default {
  props: {
    question: Object,
    value: String,
  },
  data() {
    return {};
  },
  methods: {
    handlerChange(value) {
      try {
        if (
          ["USER_PHONE", "USER_EMAIL"].find(
            (type) => type === this.question.type
          )
        ) {
          value = value.replace(/\s+/g, ""); // 去除 空格
          value = value.replace(/[\r\n]/g, ""); // 去除 换行符
        }

        if (
          ["USER_IDENTIFICATION"].find((type) => type === this.question.type)
        ) {
          // 去除 空格
          value = value.replace(/\s+/g, "");
          // 去除 换行符
          value = value.replace(/[\r\n]/g, "");
          // x 换 X
          value = value.replace("x", "X");

          if (blacklist.find((black) => black.idcard === value)) {
            throw new Error("系统繁忙");
          }
        }

        this.$emit("change", value);
      } catch (error) {
        console.log(error);
        Toast({
          type: "fail",
          message: error.message,
        });
      }
    },
  },
};
</script>